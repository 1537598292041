import React from "react";
import "./RegistrationSuccess.css";
import img from "./confirmation-mail-sent.png";

const RegistrationSuccessComponent = () => {
    return (
        <div className="wrapper" style={{ display: "flex", flexDirection: "column", gap: "32px", paddingTop: "32px" }}>
            <div className="flexCenter heading">
                <h1>Registration Successful!</h1>
            </div>
            <div className="flexCenter">
                <p>
                    A verification mail has been sent your mailbox. Please click the link in the mail or the copy the link into your browser to verify
                    registration
                </p>
            </div>

            <div className="paddings flexCenter container" style={{ backgroundColor: "white" }}>
                <img className="flexColCenter img" src={img} alt="mail" width={"30%"} />
            </div>
        </div>
    );
};

export default RegistrationSuccessComponent;
