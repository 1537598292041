import React from "react";
import AnimationRevealPage from "../../Animation";
import tw from "twin.macro";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
//import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "./signup-illustration.svg";
import logo from "./logo.svg";
//import googleIconImageSrc from "./google-icon.png";
//import twitterIconImageSrc from "./twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { useNavigate } from "react-router-dom";
import axiosRequest from "util/api";
import "./Signup.css";
import { useToast } from "@chakra-ui/react";

const ContainerBase = tw.div`relative`;

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

//const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
    ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
    .icon {
        ${tw`w-6 h-6 -ml-2`}
    }
    .text {
        ${tw`ml-3`}
    }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
    ${(props) => `background-image: url("${props.imageSrc}");`}
    ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
    logoLinkUrl = "#",
    illustrationImageSrc = illustration,
    headingText = "Sign up for FinCore",
    // socialButtons = [
    //     {
    //         iconImageSrc: googleIconImageSrc,
    //         text: "Sign Up With Google",
    //         url: "https://google.com",
    //     },
    //     {
    //         iconImageSrc: twitterIconImageSrc,
    //         text: "Sign Up With Twitter",
    //         url: "https://twitter.com",
    //     },
    // ],
    submitButtonText = "Sign Up",
    SubmitButtonIcon = SignUpIcon,
    tosUrl = "#",
    privacyPolicyUrl = "#",
    signInUrl = "#",
}) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Email is required"),
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber: Yup.string().required("Phone Number is required"),
        accountIdentifier: Yup.string().required("Account Identifier is required"),
        password: Yup.string().required("Password is required"),
    });

    const toast = useToast();

    const navigate = useNavigate();
    const form = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            phoneNumber: "",
            accountIdentifier: "",
            lastName: "",
            password: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            console.log("success", values);
            values.accountIdentifier = values.accountIdentifier.toLocaleLowerCase();
            try {
                const response = await axiosRequest.post("UserManagement/CreateAccount", values);
                console.log(response);

                if (response.status === 200) {
                    navigate("/onboarding/success");
                } else {
                    toast({
                        title: "Failed",
                        description: "Unable To Register",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                        position: "bottom-right",
                    });
                    console.error("Error creating item");
                }
            } catch (error) {
                toast({
                    title: "Failed",
                    description: "Unable To Register",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right",
                });
                console.error("Error:", error);
            }
        },
    });
    return (
        <AnimationRevealPage>
            <Container>
                <Content>
                    <MainContainer>
                        <LogoLink href={logoLinkUrl}>
                            <LogoImage src={logo} />
                        </LogoLink>
                        <MainContent>
                            <Heading>{headingText}</Heading>
                            <FormContainer>
                                {/* <SocialButtonsContainer>
              {socialButtons.map((socialButton, index) => (
                <SocialButton key={index} href={socialButton.url}>
                  <span className="iconContainer">
                    <img src={socialButton.iconImageSrc} className="icon" alt="" />
                  </span>
                  <span className="text">{socialButton.text}</span>
                </SocialButton>
              ))}
            </SocialButtonsContainer> */}
                                {/* <DividerTextContainer>
              <DividerText>Or Sign up with your e-mail</DividerText>
            </DividerTextContainer> */}
                                <form noValidate onSubmit={form.handleSubmit}>
                                    <Input
                                        name="firstName"
                                        value={form.values.firstName}
                                        type="text"
                                        placeholder="First Name"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                    <Input
                                        name="lastName"
                                        value={form.values.lastName}
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                    <Input
                                        name="phoneNumber"
                                        value={form.values.phoneNumber}
                                        type="tel"
                                        placeholder="Phone Number"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                    <Input
                                        name="accountIdentifier"
                                        value={form.values.accountIdentifier}
                                        type="text"
                                        placeholder="Account Identifier"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                    <Input
                                        name="email"
                                        value={form.values.email}
                                        type="email"
                                        placeholder="Email Address"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />

                                    <Input
                                        name="password"
                                        value={form.values.password}
                                        type="password"
                                        placeholder="Password"
                                        onChange={form.handleChange}
                                        onBlur={form.handleBlur}
                                    />
                                    <SubmitButton type="submit" isDisabled={!form.isValid || form.isSubmitting}>
                                        {form.isSubmitting ? (
                                            <div className="lds-ring">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <SubmitButtonIcon className="icon " /> <span>Sign Up</span>
                                            </div>
                                        )}
                                    </SubmitButton>
                                    <p tw="mt-6 text-xs text-gray-600 text-center">
                                        <br />I agree to abide by FinCore's{" "}
                                        <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                                            Terms of Service
                                        </a>{" "}
                                        and its{" "}
                                        <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                                            Privacy Policy
                                        </a>
                                    </p>

                                    {/* <p tw="mt-8 text-sm text-gray-600 text-center">
                                        <br />
                                        Already have an account?{" "}
                                        <a href={signInUrl} tw="border-b border-gray-500 border-dotted">
                                            Sign In
                                        </a>
                                    </p> */}
                                </form>
                            </FormContainer>
                        </MainContent>
                    </MainContainer>
                    <IllustrationContainer>
                        <IllustrationImage imageSrc={illustrationImageSrc} />
                    </IllustrationContainer>
                </Content>
            </Container>
        </AnimationRevealPage>
    );
};
