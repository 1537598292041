import React, { useEffect, useState } from "react";
import "./VerificationStyling.css";
import img from "./verification-success.png";
import axiosRequest from "util/api";
import { dashboardBaseUrl } from "environment";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import { PrimaryLink } from "../landing/light";

const VerificationSuccessComponent = () => {
    const location = useLocation();
    const accountIdentifier = window.location.pathname.split("/")[1];
    axiosRequest.defaults.headers.common["TenantKey"] = accountIdentifier;
    const [verificationStatus, setVerificationStatus] = useState(undefined);

    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("userId");
    const token = queryParams.get("token");

    const data = {
        userId,
        token,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosRequest.put("UserManagement/ConfirmEmail", data);
                console.log(response);

                if (response.data.status) {
                    setVerificationStatus(true);
                } else {
                    setVerificationStatus(false);
                }
            } catch (error) {
                console.error("Error:", error);
                setVerificationStatus(false);
            }
        };

        fetchData();
    });

    return (
        <div className="wrapper" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "24px", paddingTop: "32px" }}>
            {verificationStatus === false && (
                <div className="flexCenter heading">
                    <h1>Verification Failed!</h1>
                </div>
            )}
            {verificationStatus && (
                <>
                    <div className="flexCenter heading">
                        <h1>Verification Successful!</h1>
                    </div>
                    <div className="flexCenter">
                        <p style={{ padding: "16px" }}>Hurray! Your account has been verified</p>
                    </div>
                    <PrimaryLink css={tw`rounded-full`} to={dashboardBaseUrl.replace("{accountIdentifier}", accountIdentifier)}>
                        Sign In Now
                    </PrimaryLink>

                    <div className="paddings flexCenter container" style={{ backgroundColor: "white" }}>
                        <img className="flexColCenter img" src={img} alt="mail" width={"50%"} />
                    </div>
                </>
            )}
        </div>
    );
};

export default VerificationSuccessComponent;
