import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
//import { css } from "styled-components/macro";
import { ReactComponent as SvgDecoratorBlob3 } from "./svg-decorator-blob-3.svg";
import SupportIconImage from "./support-icon.svg";
import ShieldIconImage from "./shield-icon.svg";
import CustomizeIconImage from "./customize-icon.svg";
import FastIconImage from "./fast-icon.svg";
import ReliableIconImage from "./reliable-icon.svg";
import SimpleIconImage from "./simple-icon.svg";

const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`;
const SectionHeading = tw.h2`text-4xl sm:text-5xl font-black tracking-wide text-center`;
const SubheadingBase = tw.h5`font-bold text-primary-500`;

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
    ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
    ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
    ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
    .imageContainer {
        ${tw`border text-center rounded-full p-5 flex-shrink-0`}
        img {
            ${tw`w-6 h-6`}
        }
    }

    .textContainer {
        ${tw`sm:ml-4 mt-4 sm:mt-2`}
    }

    .title {
        ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
    }

    .description {
        ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
    }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
    ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
    cards = null,
    heading = "Amazing Features",
    subheading = "Features",
    description = "Our comprehensive platform is designed to streamline your business operations, enhance security, and boost efficiency, ensuring you stay ahead in a competitive market.",
}) => {
    /*
     * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  If a key for a particular card is not provided, a default value is used
     */

    const defaultCards = [
        {
            imageSrc: ShieldIconImage,
            title: "Increase Sales",
            description: "We exclusively engage with vendors who offer exceptional security measures.",
        },

        {
            imageSrc: SupportIconImage,
            title: "Manage Orders",
            description: "Efficiently handle both offline and online orders using our order management system.",
        },

        {
            imageSrc: CustomizeIconImage,
            title: "End-to-end tracking",
            description: "Monitor the whereabouts of individual items or batches in your inventory using the serial number and batch tracking feature.",
        },

        {
            imageSrc: ReliableIconImage,
            title: "Multiple shipping integration",
            description:
                "Access up-to-the-minute shipping rates and in-transit information from leading shipping providers to make informed decisions when selecting a shipping partner for your business.",
        },

        {
            imageSrc: FastIconImage,
            title: "Accounting and CRM integration",
            description:
                "Our smooth integration with AbiolaSoft  ensures automatic synchronization of your contacts and orders, making financial data management effortless.",
        },

        {
            imageSrc: SimpleIconImage,
            title: "Warehouse Management",
            description:
                "Inspect inventory levels, oversee inter-warehouse transfers, and generate specialized warehouse reports in a matter of seconds. Warehouse inventory management right at your fingertips.",
        },
    ];

    if (!cards) cards = defaultCards;

    return (
        <Container>
            <ThreeColumnContainer>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                {description && <Description>{description}</Description>}
                <VerticalSpacer />
                {cards.map((card, i) => (
                    <Column key={i}>
                        <Card>
                            <span className="imageContainer">
                                <img src={card.imageSrc || SupportIconImage} alt="" />
                            </span>
                            <span className="textContainer">
                                <span className="title">{card.title || "Fully Secure"}</span>
                                <p className="description">{card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}</p>
                            </span>
                        </Card>
                    </Column>
                ))}
            </ThreeColumnContainer>
            <DecoratorBlob />
        </Container>
    );
};
