import React from "react";
import GlobalStyles from "styles/GlobalStyles";
import LandingPage from "pages/landing/LandingPage.js";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./pages/signup/Signup.js";
import RegistrationSuccessComponent from "pages/registration-success-page/RegistrationSuccessComponent.jsx";
import VerificationSuccessComponent from "pages/verification-page/VerificationSuccessComponent.jsx";

export default function App() {
    const accountIdentifier = window.location.pathname.split("/")[1];

    return (
        <>
            <GlobalStyles />
            <ChakraProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/onboarding" element={<Signup />} />
                        <Route path="/onboarding/success" element={<RegistrationSuccessComponent />} />
                        <Route path="/confirm-email/" element={<VerificationSuccessComponent />} />
                        <Route path={`${accountIdentifier}/confirm-email`} element={<VerificationSuccessComponent />} />
                    </Routes>
                </Router>
            </ChakraProvider>
        </>
    );
}
