import React from "react";
import tw from "twin.macro";
//import { css } from "styled-components/macro"; //eslint-disable-line

import Hero from "./TwoColumnWithInput.js";
import Features from "./ThreeColWithSideImage.js";
import MainFeature from "./TwoColWithButton.js";
import MainFeature2 from "pages/landing/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "pages/landing/TwoColWithSteps.js";
import Pricing from "pages/landing/ThreePlans.js";
import Testimonial from "pages/landing/TwoColumnWithImageAndRating.js";
import FAQ from "pages/landing/SingleCol.js";
import GetStarted from "pages/landing/GetStarted.js";
import Footer from "pages/landing/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "./hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "./hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "./prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import AnimationRevealPage from "../../Animation.jsx";

const LandingPage = () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;

    return (
        <AnimationRevealPage>
            <Hero roundedHeaderButton={true} />
            <Features
                subheading={<Subheading>Features</Subheading>}
                heading={
                    <>
                        We have Amazing <HighlightedText>Service.</HighlightedText>
                    </>
                }
            />
            {/* <MainFeature subheading={<Subheading>Quality Work</Subheading>} imageSrc={heroScreenshotImageSrc} imageBorder={true} imageDecoratorBlob={true} /> */}
            <FeatureWithSteps
                subheading={<Subheading>STEPS</Subheading>}
                heading={
                    <>
                        Easy to <HighlightedText>Get Started.</HighlightedText>
                    </>
                }
                textOnLeft={false}
                imageSrc={macHeroScreenshotImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            {/* <MainFeature2
                subheading={<Subheading>VALUES</Subheading>}
                heading={
                    <>
                        We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
                    </>
                }
                imageSrc={prototypeIllustrationImageSrc}
                showDecoratorBlob={false}
                features={[
                    {
                        Icon: MoneyIcon,
                        title: "Affordable",
                        description: "We promise to offer you the best rate we can - at par with the industry standard.",
                        iconContainerCss: tw`bg-green-300 text-green-800`,
                    },
                    {
                        Icon: BriefcaseIcon,
                        title: "Professionalism",
                        description: "We assure you that our templates are designed and created by professional designers.",
                        iconContainerCss: tw`bg-red-300 text-red-800`,
                    },
                ]}
            /> */}
            {/* <Pricing
                subheading={<Subheading>Pricing</Subheading>}
                heading={
                    <>
                        Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
                    </>
                }
                plans={[
                    {
                        name: "Personal",
                        price: "#55,950",
                        duration: "Monthly",
                        mainFeature: "For Individuals",
                        features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"],
                    },
                    {
                        name: "Business",
                        price: "#67.980",
                        duration: "Monthly",
                        mainFeature: "For Small Businesses",
                        features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
                        featured: true,
                    },
                    {
                        name: "Enterprise",
                        price: "#97.890",
                        duration: "Monthly",
                        mainFeature: "For Large Companies",
                        features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"],
                    },
                ]}
            />
            <Testimonial
                subheading={<Subheading>Testimonials</Subheading>}
                heading={
                    <>
                        Our Clients <HighlightedText>Love Us.</HighlightedText>
                    </>
                }
                testimonials={[
                    {
                        stars: 5,
                        profileImageSrc:
                            "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                        heading: "Amazing User Experience",
                        quote: "Discover the extensive AbiolaSoft ecosystem, offering the professional services, infrastructure, support, and security essential for large businesses. Simplify complex business processes, foster strong customer relationships, and achieve scalable growth.",
                        customerName: "Amarachi Jones",
                        customerTitle: "Director, Delos Inc.",
                    },
                    {
                        stars: 5,
                        profileImageSrc:
                            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                        heading: "Love the Developer Experience and Design Principles !",
                        quote: "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
                        customerName: "Adam Cuppy",
                        customerTitle: "Founder, EventsNYC",
                    },
                ]}
            /> */}
            {/* <FAQ
                subheading={<Subheading>FAQS</Subheading>}
                heading={
                    <>
                        You have <HighlightedText>Questions ?</HighlightedText>
                    </>
                }
                faqs={[
                    {
                        question: "Are all the templates easily customizable ?",
                        answer: "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                    {
                        question: "How long do you usually support an standalone template for ?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                    {
                        question: "What kind of payment methods do you accept ?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                    {
                        question: "Is there a subscribption service to get the latest templates ?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                    {
                        question: "Are the templates compatible with the React ?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                    {
                        question: "Do you really support Internet Explorer 11 ?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                    },
                ]}
            /> */}
            {/* <GetStarted /> */}
            <Footer />
        </AnimationRevealPage>
    );
};

export default LandingPage;
